import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    redirect: "/index",
    children: [{
      path: '/index',
      name: 'home',
      component: () => import('../views/home.vue'),
    }, {
      path: '/qa',
      name: 'qa',
      component: () => import('../views/yiliao.vue'),
    }, {
      path: '/medical',
      name: 'medical',
      component: () => import('../views/bingli.vue'),
    }, {
      path: '/wendang',
      name: 'wendang',
      component: () => import('../views/wendang.vue'),
    }]
  },
]


const router = new VueRouter({
  routes
})
var getToken = function () {
  return window.localStorage.getItem('token')
}
var removeToken = function () {
  window.localStorage.clear()
}
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
    if (getToken()) { // 判断当前的token是否存在 ； 登录存入的token
      if (to.path === '/login') {
        removeToken()
        window.location.reload()
      } else {
        next()
      }
    } else {
      next({
        path: '/login',
      })
    }
  } else {
    next()
  }
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
